export const types = {
  SET_PARTNERS: 'SET_PARTNERS',
  SET_PARTNERS_ONLY: 'SET_PARTNERS_ONLY',
  SET_CURRENT_PARTNERS: 'SET_CURRENT_PARTNERS',
  CLEAR_PARTNERS: 'CLEAR_PARTNERS',
  CLEAR_SESSION: 'CLEAR_SESSION',
  SET_LOGO_IMAGE: 'SET_LOGO_IMAGE',
  SET_VOID_CHEQUE_VERIFIED: 'SET_VOID_CHEQUE_VERIFIED',
};

export const setPartners = (res) => ({
  type: types.SET_PARTNERS,
  payload: {
    partners: res.Partners,
  },
});

export const setPartnersOnly = (partners) => ({
  type: types.SET_PARTNERS_ONLY,
  payload: partners,
});

export const setCurrentPartner = (partner) => ({
  type: types.SET_CURRENT_PARTNERS,
  payload: partner,
});

export const setLogoImage = (logoString) => ({
  type: types.SET_LOGO_IMAGE,
  payload: logoString,
});

export const setVoidChequeVerified = (isVerified) => ({
  type: types.SET_VOID_CHEQUE_VERIFIED,
  payload: isVerified,
});

export const clearPartner = () => ({ type: types.CLEAR_PARTNERS });
