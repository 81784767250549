import NextLink from 'next/link';
import { getLanguage } from 'react-switch-lang';

import { logAmpEvent } from '../utils/Amplitude';
import style from '../styles/Link.module.scss';

/**
 * Custom `<a>` tag that links to a relative path.
 *
 * If both text and children are provided, only children will be rendered.
 *
 * Any extra props provided will be passed to `<a>`.
 * @param {object} props
 * @param {string | import('url').UrlObject} props.href
 *   relative path to navigate to, or a URL object
 * @param {string} [props.text] text to be displayed for the link
 * @param {React.ReactNode} [props.children] ReactNode to be rendered inside `<a>`
 * @param {string} props.ampEvent Amplitude event to be logged when this link is clicked
 * @param {object} [props.ampEventProps] Amplitude event properties to be logged with the event
 * @param {string} [props.id]
 * @param {string} [props.className] appended after the default class, "link"
 * @param {Function} [props.onClick] callback to be executed on click
 */
export default function Link({
  href, text, children, ampEvent, ampEventProps, className = '', ...props
}) {
  let path;
  const lang = getLanguage();
  if (typeof href === 'string') {
    path = `/${lang}${href}`;
  } else {
    path = { ...href, pathname: `/${lang}${href.pathname}` };
  }
  return (
    <NextLink
      href={path}
      passHref
      {...props}
      className={`${style.link} ${className}`}
      onClick={
        (ampEvent || props.onClick) &&
        ((e) => {
          if (ampEvent) logAmpEvent(ampEvent, ampEventProps);
          props.onClick?.(e);
        })
      }
    >
      {children || text}
    </NextLink>
  );
}

/**
 * Custom `<a>` tag that links to an external URL.
 *
 * If both text and children are provided, only children will be rendered.
 *
 * Any extra props provided will be passed to the `<a>`.
 * @param {object} props
 * @param {string} props.href external URL to navigate to
 * @param {string} [props.text] text to be displayed for the link
 * @param {React.ReactNode} [props.children] ReactNode to be rendered inside `<a>`
 * @param {string} props.ampEvent Amplitude event to be logged when this link is clicked
 * @param {object} [props.ampEventProps] Amplitude event properties to be logged with the event
 * @param {string} [props.id]
 * @param {string} [props.className] appended after the default class, "link"
 * @param {Function} [props.onClick] callback to be executed on click
 */
export function ExternalLink({
  href, noTargetBlank = false, text, children, ampEvent, ampEventProps, className = '', ...props
}) {
  return (
    <a
      target={`${!noTargetBlank ? '_blank' : '_self'}`}
      rel="noreferrer"
      {...props}
      href={href}
      className={`${style.link} ${className}`}
      onClick={
        (ampEvent || props.onClick) &&
        ((e) => {
          if (ampEvent) logAmpEvent(ampEvent, ampEventProps);
          props.onClick?.(e);
        })
      }
    >
      {children || text}
    </a>
  );
}
