import { types } from '../actions/ReportActions';

export const reportsInitialState = {
  // null records mean no attempt has been made to fetch them
  paymentsReport: {
    records: null,
    startDate: null,
    endDate: null,
    loading: false,
  },
  showReportTooltips: true,
};

// eslint-disable-next-line default-param-last
function ReportsReducer(state = reportsInitialState, action) {
  switch (action.type) {
    case types.CLEAR_ALL_REPORT_DATA:
      return { ...reportsInitialState };
    case types.SET_SHOW_REPORT_TOOLTIPS:
      return { ...state, showReportTooltips: action.payload };

    // Payments report
    case types.CLEAR_PAYMENTS_REPORT:
      return { ...state, paymentsReport: reportsInitialState.paymentsReport };
    case types.SET_PAYMENTS_REPORT:
      return { ...state, paymentsReport: action.payload };
    case types.SET_PAYMENTS_REPORT_LOADING:
      return {
        ...state,
        paymentsReport: { ...state.paymentsReport, loading: action.payload },
      };

    default:
      return state;
  }
}

export default ReportsReducer;
