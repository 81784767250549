/* eslint-disable no-undef */
import Head from 'next/head';
import { t } from 'react-switch-lang';
import React from 'react';
import { setPageTitle } from '../utils/Accessibility';
import NavBar from './NavBar';
import Footer from './Footer';
import SkipToContent from './SkipToContent';
import SkipToNav from './SkipToNav';

/**
 * Layout wrapper for all pages.
 *
 * Renders NavBar and Footer, alongside any children.
 *
 * Handles accessibility such as page titles and renders skip to links.
 *
 * @param {object} props
 * @param {string} props.title accessible page title passed into `<Head>`
 * @param {string} [props.description] description meta tag passed into `<Head>`
 * @param {boolean} [props.isLandingPage] indicates if the page is the landing page
 * @param {boolean} [props.authPage] indicates if the page is an auth page
 * @param {boolean} [props.showLanguageSwitcher] indicates if the page should show language switcher
 * @param {React.ReactNode} [props.children] ReactNode to be rendered inside wrapper
 * @param {string} [props.className] appended after the default class, "content"
 */
export default function MainLayout({
  title,
  description,
  isLandingPage = false,
  authPage = false,
  showLanguageSwitcher = false,
  children,
  className = '',
  hideNavAndFooter = false,
}) {
  // Page title for accesibility
  if (title) setPageTitle(title);

  return (
    <>
      <Head>
        {/* eslint-disable-next-line no-nested-ternary */}
        {title ? isLandingPage ? <title>{title}</title> : <title>{`${title} | ${t('OfficialSiteName')}`}</title> : <title>{t('OfficialSiteName')}</title>}
        {description && <meta name="description" content={description} />}
        <link rel="icon" href="/favicon.png" />
      </Head>
      <div id="main-cont">
        <SkipToContent />
        { hideNavAndFooter ||
          <NavBar isLandingPage={isLandingPage} authPage={authPage} /> }
        <div id="main" className={`content ${className}`}>
          {children}
        </div>
        { hideNavAndFooter ||
          <Footer showLanguageSwitcher={showLanguageSwitcher} /> }
        <SkipToNav />
      </div>
    </>
  );
}
