import { setLanguage, setTranslations } from 'react-switch-lang';
import Script from 'next/script';
import { Provider } from 'react-redux';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { useRouter } from 'next/router';

import { useStore } from '../redux/Store';
import '../styles/Globals.scss';
import '../styles/Pace.scss';
import '../styles/bootstrap.scss';
import { isProduction } from '../utils/HostingEnv';
import English from '../languages/English.json';
import French from '../languages/French.json';
import { LANGUAGES } from '../utils/Constants';
import Redirect from '../components/Redirect';
import NotFound from './404';

config.autoAddCss = false;

setTranslations({
  en: English,
  fr: French,
});

// If you add any page to this list that requires Redux data,
// please ensure the path is included in the NavBar.js logout onClick.
// The logout function handles routing based on the page path.
const pages = [
  '/login',
  '/register',
  '/error',
  '/services',
  '/maintenance',
  '/reports',
  '/reports/select-partner',
  '/application/contact-information',
  '/application/before-you-start',
  '/application/service-information',
  '/application/payment-options',
  '/application/agreement',
  '/application/paysimply-preferences',
];
const hiddenPages = [
  '/[lang]/forgot-password',
  '/[lang]/logout',
];

function App({ Component, pageProps }) {
  const store = useStore();
  const router = useRouter();
  let {
    query: { lang },
  } = router;
  const { asPath: path, pathname } = router;

  // if 404 page
  if (pathname === '/404') {
    const langPath = path.split('/')[1];
    // if 404 path contains a lang, set current lang
    if (Object.keys(LANGUAGES).includes(langPath.toUpperCase())) {
      lang = langPath;
    }
  }

  try {
    if (typeof localStorage === 'undefined') {
      lang = lang || LANGUAGES.EN.code;
    } else {
      if (lang) {
      // lang in query takes precedence over saved lang in localStorage
        localStorage.setItem('language', lang);
      } else {
        // if there's no lang passed in from the URL, try to retrieve saved lang
        lang = localStorage.getItem('language');
        // if there's no saved lang, save en
        if (!Object.keys(LANGUAGES).includes(lang.toUpperCase())) {
          lang = LANGUAGES.EN.code;
          localStorage.setItem('language', LANGUAGES.EN.code);
        }
      }
      // save referral partner in localStorage (used in /service-information)
      const referral = new URLSearchParams(window.location.search).get('referral');
      if (referral !== null) {
        localStorage.setItem('referral', JSON.stringify({
          referral,
          timestamp: Date.now(),
        }));
      }
    }
  } catch (err) {
    // try-catch just here to prevent exception in Sentry
  }

  setLanguage(lang);

  return (
    <Provider store={store}>
      {path.slice(1, 7) === '[lang]' && hiddenPages.some((page) => page.includes(path.slice(7))) &&
        <NotFound {...pageProps} lang={lang} />}
      {/* add lang to path if not specified */}
      {pages.includes(path) ? (
        <Redirect path={`/${lang}${path}`} asIs />
      ) : (
        <Component {...pageProps} lang={lang} />
      )}
      {isProduction && (
        <Script
          src="https://www.googletagmanager.com/gtag/js?id=G-RE2VWN1RMV"
          strategy="afterInteractive"
        />
      )}
    </Provider>
  );
}

export default App;
