import { types } from '../actions/AuthActions';

export const authInitialState = {
  session: null,
  email: null,
};

// eslint-disable-next-line default-param-last
function AuthReducer(state = authInitialState, action) {
  switch (action.type) {
    case types.SET_EMAIL:
      return { ...state, email: action.payload };
    case types.SET_SESSION:
      return { ...state, session: action.payload };
    case types.CLEAR_SESSION:
      return authInitialState;
    default:
      return state;
  }
}

export default AuthReducer;
