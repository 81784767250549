/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react';
import { createStore } from 'redux';
import reducers from './reducers';

let store;

const initialState = {
  auth: reducers.auth,
  partner: reducers.partner,
  reports: reducers.reports,
};

function initStore(preloadedState = initialState) {
  return createStore(reducers, preloadedState);
}

export function initializeStore(preloadedState) {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
}

export function useStore(preloadedState) {
  const _store = useMemo(
    () => initializeStore(preloadedState),
    [preloadedState]
  );
  return _store;
}
