import { Fragment } from 'react';

/**
 * Replaces instances of replaceKey with replaceWith in text.
 * @param {object} props
 * @param {string} props.text text to be converted
 * @param {string} props.replaceKey pattern to be replaced
 * @param {React.ReactNode} props.replaceWith ReactNode to replace the replaceKey with
 * @param {string[]} [props.exceptions]
 *   prevents replacement if replaceKey is followed by one of these
 * @returns {string | React.ReactNode[]} orignal text if not found, else array of ReactNodes
 */
export default function ReplaceTextWithElement({
  text,
  replaceKey,
  replaceWith,
  exceptions,
}) {
  if (!text.includes(replaceKey)) return text;

  return text.split(replaceKey).map((e, i) => {
    if (i === 0) return e;
    return (
      <Fragment key={e}>
        {exceptions?.some((exception) => e.startsWith(exception)) ? replaceKey : replaceWith}
        {e}
      </Fragment>
    );
  });
}
