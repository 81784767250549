import { getLanguage, setLanguage, t } from 'react-switch-lang';
import Image from 'next/legacy/image';

import { ExternalLink } from './Link';
import { events, logAmpEvent } from '../utils/Amplitude';
import style from '../styles/Footer.module.scss';
import { LANGUAGES } from '../utils/Constants';
import ReplaceTextWithElement from './ReplaceTextWithElement';
import Button from './Button';
import { useRouter } from './Router';

function FooterLink({ langKey, href, ariaLabel, className }) {
  return (
    <ExternalLink
      href={href}
      ampEvent={events.USER_CLICKED_FOOTER_LINK}
      ampEventProps={{ Link: t(langKey, null, LANGUAGES.EN.code) }}
      text={t(langKey)}
      aria-label={ariaLabel}
      className={className}
    />
  );
}

export default function Footer({ showLanguageSwitcher }) {
  const router = useRouter();
  const ar = router.pathname.split('[lang]');
  const curPath = ar[ar.length - 1];

  function languageToFull() {
    return getLanguage() === LANGUAGES.FR.code ? LANGUAGES.EN.label : LANGUAGES.FR.label;
  }

  function languageToCode() {
    return getLanguage() === LANGUAGES.FR.code ? LANGUAGES.EN.code : LANGUAGES.FR.code;
  }

  function selectLanguage() {
    const lang = languageToCode();
    if (getLanguage() === lang) return;
    logAmpEvent(events.USER_CHANGED_LANGUAGE, { Language: lang === LANGUAGES.FR.code ? 'French' : 'English' });
    document.documentElement.lang = lang;
    if (router.pathname === '/404') {
      setLanguage(lang);
      router.pushStatic('/404', router.asPath);
    } else {
      router.pushStatic(`/${lang}${curPath}`);
    }
  }

  return (
    <footer id="footer" className={style.footer}>
      <div className={style.miniFooter}>
        <div className={style.footerLinks}>
          <FooterLink langKey="Footer_PrivacyPolicy" href={t('Link_PaySimply_Partner_Portal_Web_PrivacyPolicy')} ariaLabel={t('LinkDescription_PrivacyPolicy')} className={style.miniFooterLink} />
          <FooterLink langKey="Footer_TermsAndConditions" href={t('Link_PaySimply_Partner_Portal_Web_Terms')} ariaLabel={t('LinkDescription_Terms')} className={style.miniFooterLink} />
          {showLanguageSwitcher && (
            <Button
              linkBtn
              text={languageToFull()}
              ariaLabel={t('Language_Switch_To', null, getLanguage())}
              className={style.miniFooterLink}
              onClick={() => { selectLanguage(); }}
              lang={languageToCode()}
            />
          )}

        </div>
        <div>
          <p className={style.supportText}>
            <ReplaceTextWithElement
              text={t('Footer_EmailContactInfo')}
              replaceWith={<a aria-label={t('LinkDescription_PaySimplyContactEmail')} href={`mailto:${t('PaySimplyContactEmail')}`}>{t('PaySimplyContactEmail')}</a>}
              replaceKey="xPaySimplyContactEmail"
            />
          </p>
        </div>
      </div>
      <div id={style.bottom}>
        <div className={style.constFooter}>
          <div className={style.badges}>
            <Image
              alt={t('ImageDescription_BestManagedLogo')}
              src="/assets/footer/CBMCLogo.png"
              width={95}
              height={35}
              unoptimized
            />
            <Image
              alt={t('ImageDescription_100CanadianLogo')}
              src="/assets/footer/100CanadianLogo.png"
              width={100}
              height={55}
              unoptimized
            />
          </div>
          <div className={style.bottomText}>
            <p>{t('Footer_Copyright_Text1')}</p>
            <p>
              <ReplaceTextWithElement
                text={t('Footer_Copyright_Text2')}
                replaceWith={<i>Interac</i>}
                replaceKey="Interac"
              />
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
