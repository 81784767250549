import { useEffect, useState } from 'react';
import { t, getLanguage } from 'react-switch-lang';

import { useSelector } from 'react-redux';
import styles from '../styles/ErrorPage.module.scss';
import MainLayout from '../components/MainLayout';
import Button from '../components/Button';
import { useRouter, checkSession } from '../components/Router';
import { events, logAmpEvent } from '../utils/Amplitude';

function Custom404() {
  const router = useRouter();
  const session = useSelector((state) => !!state.auth.session);
  const [title, setTitle] = useState(t('PageTitle_Loading'));
  const [lang, setLang] = useState('en');

  useEffect(() => {
    logAmpEvent(events.USER_VIEWED_ERROR_PAGE, {
      Error: '404',
      URL: router.asPath,
    });
    setTitle(t('PageTitle_404'));
    document.documentElement.setAttribute('lang', getLanguage());
    setLang(getLanguage());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainLayout title={title}>
      <div className={styles.errorPageContainer}>
        <h1>{t('Error404_Title')}</h1>
        <p>{t('Error404_Subtitle')}</p>
        <Button
          ariaLabel={
            session ? t('ErrorBtn_LoggedIn_AriaLabel', null, lang) : t('ErrorBtn_LoggedOut_AriaLabel', null, lang)
          }
          text={t('Error404_Btn')}
          ampEvent={events.USER_CLICKED_ERROR_PAGE_BUTTON}
          ampEventProps={{
            Error: '404',
            URL: router.asPath,
          }}
          onClick={() => router.push('/')}
        />
      </div>
    </MainLayout>
  );
}

export default checkSession(Custom404);
