import Timezones from '../content/Timezones.json';

export const LANGUAGES = {
  EN: { code: 'en', label: 'English' },
  FR: { code: 'fr', label: 'Français' },
};

export const PAYMENT_OPTIONS = {
  CREDIT: { code: 'CREDIT', label: 'Credit' },
  ETRANSFER: { code: 'ETRANSFER', label: 'eTransfer' },
  INPERSON: { code: 'INPERSON', label: 'InPerson' },
  PAYPAL: { code: 'PAYPAL', label: 'PayPal' },
};

export const AUTH_STEP = {
  LOGIN: 'Login',
  REGISTER: 'Register',
  CONFIRM_EMAIL: 'Confirm Email',
  FORGOT_PASSWORD: 'Forgot Password',
  VERIFY_EMAIL: 'Verify Email',
};

export const AUTH_API = {
  INITIATE_AUTH: 'InitateAuth',
  REGISTER: 'SignUp',
  CONFIRM_EMAIL: 'ConfirmSignUp',
  RESEND_CONFIRM_EMAIL: 'ResendConfirmationCode',
  FORGOT_PASSWORD: 'ForgotPassword',
  CONFIRM_FORGOT_PASSWORD: 'ConfirmForgotPassword',
};

export const PARTNER_API = {
  UPDATE_PARTNER: 'UpdatePartner',
  UPDATE_SETTINGS: 'Xero/UpdateSettings',
  GET_PARTNERS: 'GetPartners',
  DEACTIVATE_PARTNER: 'DeactivatePartner',
  MAINTENANCE_MODE: 'MaintenanceMode',
};

export const REPORTS_API = {
  GET_PAYMENTS_REPORT: 'Report/GetPaymentsReport',
};

export const DYNAMIC_FIELDS = {
  PAYER_NAME: 'PayerName',
  CLIENT_REF: 'ClientRef',
  INVOICE_NO: 'InvoiceNo',
  PARTNERFIELD_01: 'PartnerField01',
  PARTNERFIELD_02: 'PartnerField02',
  PARTNERFIELD_03: 'PartnerField03',
  PARTNERFIELD_04: 'PartnerField04',
  PARTNERFIELD_05: 'PartnerField05',
};

export const PAYMENT_OPTIONS_LANG_KEYS = {
  M: 'Service_PaymentOption_Credit',
  J: 'Service_PaymentOption_Debit',
  T: 'Service_PaymentOption_eTransfer',
  G: 'Service_PaymentOption_eTransfer',
  F: 'Service_PaymentOption_eTransfer',
  A: 'Service_PaymentOption_InPersonCash',
  D: 'Service_PaymentOption_InPersonDebit',
  P: 'Service_PaymentOption_PayPal',
};

export const APP_TYPES = {
  PAYSIMPLY_MOBILE: 'PaySimplyMobile',
  PAYSIMPLY: 'PaySimply',
};

export const APP_LANG_KEYS = {
  PaySimplyMobile: 'Reports_App_MobileApp',
  PaySimply: 'Reports_App_Web',
};

// Used for determining the direction of sorting in reports.
export const DIRECTION = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC',
};

// Constant defining types of reports
// Used as event props for the Amplitude events
// Also used to determine the currently selected report, values are lowercased for query parameters
// Do not change the values unless necessary, as they are used as identifiers.
// Paysimply-Partner-Portal-Web only supports payments reports.
export const REPORTS = {
  PAYMENTS: 'Payments',
};

export const TIMEZONE_CODES = Timezones.reduce((acc, tz) => {
  acc[tz.Code] = Number.parseFloat(tz.Value);
  return acc;
}, {});

export const SUCCESS_ALERT = {
  UPDATE_PARTNER: 'UpdatePartner',
  UPDATE_SETTINGS: 'UpdateSettings',
  ACTIVATE: 'Activate',
};

export const PARTNER_DETAILS_STEP = {
  SERVICE_INFO: 'ServiceInformation',
  CONTACT_INFO: 'ContactInformation',
  PREFERENCES: 'Preferences',
  PAYMENT_OPTIONS: 'PaymentOptions',
  AGREEMENT: 'Agreement',
};

export const EMPTY_PARTNER = {
  CreatedBy: '',
  Status: '',
  ActivationDate: '',
  SubmittedDate: '',
  StartedReviewDate: '',
  InactiveDate: '',
  Language: '',
  Partner_Seq: '',
  LegalName: '',
  BinNumber: '',
  GstHstNumber: '',
  DateIncorporated: '',
  LegalStructure: '',
  BusinessWebsiteUrl: '',
  Street: '',
  UnitSuite: '',
  City: '',
  Province: '',
  PostalCode: '',
  ServiceCategory: '',
  CountriesOfBusiness: [],
  AverageTransactionValue: '',
  MonthlySalesVolume: '',
  LeadSource: '',
  ExistingPaymentOffered: [],
  ReportsEmail: '',
  PrimaryIndividual: {
    FullName: '',
    PercentOwnership: '',
    DateOfBirth: '',
    Address: {
      Unit: '',
      Street: '',
      City: '',
      Province: '',
      PostalCode: '',
    },
    PartnerIndividual_Seq: '',
    Phone: '',
    Email: '',
  },
  AuthorizedIndividual: {
    FullName: '',
    PercentOwnership: '',
    DateOfBirth: '',
    Address: {
      Unit: '',
      Street: '',
      City: '',
      Province: '',
      PostalCode: '',
    },
    PartnerIndividual_Seq: '',
    Phone: '',
    Email: '',
  },
  BusinessOwnershipIndividuals: [
  ],
  DisplayNameEn: '',
  DisplayNameFr: '',
  VanityUrl: '',
  Logo: '',
  LogoAltEn: '',
  LogoAltFr: '',
  AccountNumberConfig: {
    LabelEn: '',
    HelpTextEn: '',
    PlaceholderEn: '',
    LabelFr: '',
    HelpTextFr: '',
    PlaceholderFr: '',
    FormatType: '',
    MinLength: '',
    MaxLength: '',
    Other: '',
  },
  OtherEmail: '',
  AgreementSignedDate: '',
  BankTransitNumber: '',
  BankBranch: '',
  BankAccountNumber: '',
  StatementDescriptor: '',
  PaymentOption: [],
  LogoBase: null,
  LogoUrl: '',
};

export const HARDCODED_PARTNERS =
  [
    {
      CreatedBy: '',
      Status: 'A',
      ActivationDate: '',
      SubmittedDate: '2023-01-25',
      StartedReviewDate: '',
      InactiveDate: '2023-01-24',
      Language: 'English',
      Partner_Seq: '11111',
      LegalName: 'Jupiter Coffee Taxes',
      BinNumber: '987654321',
      GstHstNumber: '123411234',
      DateIncorporated: '2022-08-31',
      LegalStructure: 'Legal Structure',
      BusinessWebsiteUrl: 'PP.com',
      Street: '123 Brew St',
      UnitSuite: '',
      City: 'Port Moody',
      Province: 'BC',
      PostalCode: 'V1V 1V1',
      ServiceCategory: '',
      CountriesOfBusiness: ['Canada', 'USA'],
      AverageTransactionValue: '987321',
      MonthlySalesVolume: '123456789',
      LeadSource: 'Name',
      ExistingPaymentOffered: ['PAD'],
      ReportsEmail: 'kevins+reportsemail@paymentsource.ca',
      PrimaryIndividual: {
        FullName: 'Primary 43598',
        PercentOwnership: '15',
        DateOfBirth: '1990-01-01',
        Address: {
          Unit: '',
          Street: 'Brew St',
          City: 'Pomo',
          Province: 'BC',
          PostalCode: 'V1V 1V1',
        },
        PartnerIndividual_Seq: '677',
        Phone: '777-777-7777',
        Email: 'example@mybusiness.ca',
      },
      AuthorizedIndividual: {
        FullName: '',
        PercentOwnership: '',
        DateOfBirth: '',
        Address: {
          Unit: '',
          Street: '',
          City: '',
          Province: '',
          PostalCode: '',
        },
        PartnerIndividual_Seq: '',
        Phone: '',
        Email: '',
      },
      BusinessOwnershipIndividuals: [
        {
          FullName: 'Authorized 43598',
          PercentOwnership: '16',
          DateOfBirth: '1990-01-01',
          Address: {
            Unit: '',
            Street: 'Brew St',
            City: 'Pomo',
            Province: 'BC',
            PostalCode: 'V1V 1V1',
          },
          PartnerIndividual_Seq: '679',
          Phone: '777-777-7777',
          Email: 'Authorized+43598@paymentsource.ca',
        },
      ],
      DisplayNameEn: 'Jupiter Coffee Taxes',
      DisplayNameFr: 'Cafe Taxes de Jupiter',
      PaymentUrlEn: 'https://www.paysimply.ca/pay/coffee',
      PaymentUrlFr: 'https://www.paysimply.ca/payer/coffee',
      VanityUrl: 'coffee',
      Logo: '',
      LogoAltEn: 'logo alt en',
      LogoAltFr: 'logo alt fr',
      AccountNumberConfig: {
        LabelEn: 'Coffee Account Number',
        HelpTextEn: '',
        PlaceholderEn: 'Placeholder en',
        LabelFr: 'label en',
        HelpTextFr: 'label en',
        PlaceholderFr: 'label en',
        FormatType: '999999999',
        MinLength: '09',
        MaxLength: '',
        Other: 'a',
      },
      OtherEmail: 'otheremail@paymentsource.ca',
      AgreementSignedDate: '2022-08-13',
      BankTransitNumber: '12312',
      BankBranch: '123',
      BankAccountNumber: '*****21',
      StatementDescriptor: '123456789012',
      PaymentOption: ['CREDIT', ' PAYPAL', ' ETRANSFER'],
      LogoBase: null,
      LogoUrl: 'https://www.paymentsource.ca/hs-fs/hubfs/paymentsourcelogo_colour-1.png?width=1153&name=paymentsourcelogo_colour-1.png',
    },
    {
      CreatedBy: '',
      Status: 'A',
      ActivationDate: '',
      SubmittedDate: '2023-01-25',
      StartedReviewDate: '',
      InactiveDate: '2023-01-24',
      Language: 'English',
      Partner_Seq: '12345',
      LegalName: 'Jupiter Property Taxes',
      BinNumber: '987654321',
      GstHstNumber: '123411234',
      DateIncorporated: '2022-08-31',
      LegalStructure: 'Legal Structure',
      BusinessWebsiteUrl: 'PP.com',
      Street: '123 Brew St',
      UnitSuite: '',
      City: 'Port Moody',
      Province: 'BC',
      PostalCode: 'V1V 1V1',
      ServiceCategory: '',
      CountriesOfBusiness: ['Canada', 'USA'],
      AverageTransactionValue: '987321',
      MonthlySalesVolume: '123456789',
      LeadSource: 'Name',
      ExistingPaymentOffered: ['PAD'],
      ReportsEmail: 'kevins+reportsemail@paymentsource.ca',
      PrimaryIndividual: {
        FullName: 'Primary 43598',
        PercentOwnership: '15',
        DateOfBirth: '1990-01-01',
        Address: {
          Unit: '',
          Street: 'Brew St',
          City: 'Pomo',
          Province: 'BC',
          PostalCode: 'V1V 1V1',
        },
        PartnerIndividual_Seq: '677',
        Phone: '777-777-7777',
        Email: 'example@mybusiness.ca',
      },
      AuthorizedIndividual: {
        FullName: '',
        PercentOwnership: '',
        DateOfBirth: '',
        Address: {
          Unit: '',
          Street: '',
          City: '',
          Province: '',
          PostalCode: '',
        },
        PartnerIndividual_Seq: '',
        Phone: '',
        Email: '',
      },
      BusinessOwnershipIndividuals: [
        {
          FullName: 'Authorized 43598',
          PercentOwnership: '16',
          DateOfBirth: '1990-01-01',
          Address: {
            Unit: '',
            Street: 'Brew St',
            City: 'Pomo',
            Province: 'BC',
            PostalCode: 'V1V 1V1',
          },
          PartnerIndividual_Seq: '679',
          Phone: '777-777-7777',
          Email: 'Authorized+43598@paymentsource.ca',
        },
      ],
      DisplayNameEn: 'Jupiter Property Taxes',
      DisplayNameFr: 'Property Taxes de Jupiter',
      PaymentUrlEn: 'https://www.paysimply.ca/Partner/Details.aspx?id=12345',
      PaymentUrlFr: 'https://www.paysimply.ca/Partner/Details.aspx?id=12345&Site=French',
      VanityUrl: '',
      Logo: '',
      LogoAltEn: 'logo alt en',
      LogoAltFr: 'logo alt fr',
      AccountNumberConfig: {
        LabelEn: 'Tax Account Number',
        HelpTextEn: '',
        PlaceholderEn: 'Placeholder en',
        LabelFr: 'label en',
        HelpTextFr: 'label en',
        PlaceholderFr: 'label en',
        FormatType: '999999999',
        MinLength: '09',
        MaxLength: '',
        Other: 'a',
      },
      OtherEmail: 'otheremail@paymentsource.ca',
      AgreementSignedDate: '2022-08-13',
      BankTransitNumber: '12312',
      BankBranch: '123',
      BankAccountNumber: '*****21',
      StatementDescriptor: '123456789012',
      PaymentOption: ['CREDIT', ' PAYPAL', ' ETRANSFER'],
      LogoBase: null,
      LogoUrl: '',
    },
    {
      CreatedBy: '',
      Status: 'R',
      ActivationDate: '',
      SubmittedDate: '2023-01-25',
      StartedReviewDate: '',
      InactiveDate: '2023-01-24',
      Language: 'English',
      Partner_Seq: '3',
      LegalName: 'Jupiter Utilities',
      BinNumber: '987654321',
      GstHstNumber: '123411234',
      DateIncorporated: '2022-08-31',
      LegalStructure: 'Legal Structure',
      BusinessWebsiteUrl: 'PP.com',
      Street: '123 Brew St',
      UnitSuite: '',
      City: 'Port Moody',
      Province: 'BC',
      PostalCode: 'V1V 1V1',
      ServiceCategory: '',
      CountriesOfBusiness: ['Canada', 'USA'],
      AverageTransactionValue: '987321',
      MonthlySalesVolume: '123456789',
      LeadSource: 'Name',
      ExistingPaymentOffered: ['PAD'],
      ReportsEmail: 'kevins+reportsemail@paymentsource.ca',
      PrimaryIndividual: {
        FullName: 'Primary 43598',
        PercentOwnership: '15',
        DateOfBirth: '1990-01-01',
        Address: {
          Unit: '',
          Street: 'Brew St',
          City: 'Pomo',
          Province: 'BC',
          PostalCode: 'V1V 1V1',
        },
        PartnerIndividual_Seq: '677',
        Phone: '777-777-7777',
        Email: 'example@mybusiness.ca',
      },
      AuthorizedIndividual: {
        FullName: '',
        PercentOwnership: '',
        DateOfBirth: '',
        Address: {
          Unit: '',
          Street: '',
          City: '',
          Province: '',
          PostalCode: '',
        },
        PartnerIndividual_Seq: '',
        Phone: '',
        Email: '',
      },
      BusinessOwnershipIndividuals: [
        {
          FullName: 'Authorized 43598',
          PercentOwnership: '16',
          DateOfBirth: '1990-01-01',
          Address: {
            Unit: '',
            Street: 'Brew St',
            City: 'Pomo',
            Province: 'BC',
            PostalCode: 'V1V 1V1',
          },
          PartnerIndividual_Seq: '679',
          Phone: '777-777-7777',
          Email: 'Authorized+43598@paymentsource.ca',
        },
      ],
      DisplayNameEn: 'Jupiter Utilities',
      DisplayNameFr: 'Utilitaires Jupiter',
      PaymentUrlEn: 'https://www.paymentsource.co/paysimply/RC/pay/ppppp',
      PaymentUrlFr: 'https://www.paymentsource.co/paysimply/RC/payer/ppppp',
      VanityUrl: 'ppppp',
      Logo: '',
      LogoAltEn: 'logo alt en',
      LogoAltFr: 'logo alt fr',
      AccountNumberConfig: {
        LabelEn: 'label en',
        HelpTextEn: '',
        PlaceholderEn: 'Placeholder en',
        LabelFr: 'label en',
        HelpTextFr: 'label en',
        PlaceholderFr: 'label en',
        FormatType: '999999999',
        MinLength: '09',
        MaxLength: '',
        Other: 'a',
      },
      OtherEmail: 'otheremail@paymentsource.ca',
      AgreementSignedDate: '2022-08-13',
      BankTransitNumber: '12312',
      BankBranch: '123',
      BankAccountNumber: '*****21',
      StatementDescriptor: '123456789012',
      PaymentOption: ['CREDIT', ' ETRANSFER', ' NONE'],
      LogoBase: null,
      LogoUrl: 'https://www.paymentsource.ca/hs-fs/hubfs/paymentsourcelogo_colour-1.png?width=1153&name=paymentsourcelogo_colour-1.png',
    },
    {
      CreatedBy: '',
      Status: 'P',
      ActivationDate: '',
      SubmittedDate: '2023-01-25',
      StartedReviewDate: '',
      InactiveDate: '2023-01-24',
      Language: 'English',
      Partner_Seq: '4',
      LegalName: 'Jupiter Taxes',
      BinNumber: '987654321',
      GstHstNumber: '123411234',
      DateIncorporated: '2022-08-31',
      LegalStructure: 'Legal Structure',
      BusinessWebsiteUrl: 'PP.com',
      Street: '123 Brew St',
      UnitSuite: '',
      City: 'Port Moody',
      Province: 'BC',
      PostalCode: 'V1V 1V1',
      ServiceCategory: '',
      CountriesOfBusiness: ['Canada', 'USA'],
      AverageTransactionValue: '987321',
      MonthlySalesVolume: '123456789',
      LeadSource: 'Name',
      ExistingPaymentOffered: ['PAD'],
      ReportsEmail: 'kevins+reportsemail@paymentsource.ca',
      PrimaryIndividual: {
        FullName: 'Primary 43598',
        PercentOwnership: '15',
        DateOfBirth: '1990-01-01',
        Address: {
          Unit: '',
          Street: 'Brew St',
          City: 'Pomo',
          Province: 'BC',
          PostalCode: 'V1V 1V1',
        },
        PartnerIndividual_Seq: '677',
        Phone: '777-777-7777',
        Email: 'Prmary+43598@paymentsource.ca',
      },
      AuthorizedIndividual: {
        FullName: '',
        PercentOwnership: '',
        DateOfBirth: '',
        Address: {
          Unit: '',
          Street: '',
          City: '',
          Province: '',
          PostalCode: '',
        },
        PartnerIndividual_Seq: '',
        Phone: '',
        Email: '',
      },
      BusinessOwnershipIndividuals: [
        {
          FullName: 'Authorized 43598',
          PercentOwnership: '16',
          DateOfBirth: '1990-01-01',
          Address: {
            Unit: '',
            Street: 'Brew St',
            City: 'Pomo',
            Province: 'BC',
            PostalCode: 'V1V 1V1',
          },
          PartnerIndividual_Seq: '679',
          Phone: '777-777-7777',
          Email: 'Authorized+43598@paymentsource.ca',
        },
      ],
      DisplayNameEn: 'Jupiter Taxes',
      DisplayNameFr: 'Impôts sur Jupiter',
      PaymentUrlEn: 'https://www.paymentsource.co/paysimply/RC/pay/ppppp',
      PaymentUrlFr: 'https://www.paymentsource.co/paysimply/RC/payer/ppppp',
      VanityUrl: 'ppppp',
      Logo: '',
      LogoAltEn: 'logo alt en',
      LogoAltFr: 'logo alt fr',
      AccountNumberConfig: {
        LabelEn: 'label en',
        HelpTextEn: '',
        PlaceholderEn: 'Placeholder en',
        LabelFr: 'label en',
        HelpTextFr: 'label en',
        PlaceholderFr: 'label en',
        FormatType: '999999999',
        MinLength: '09',
        MaxLength: '',
        Other: 'a',
      },
      OtherEmail: 'otheremail@paymentsource.ca',
      AgreementSignedDate: '2022-08-13',
      BankTransitNumber: '12312',
      BankBranch: '123',
      BankAccountNumber: '*****21',
      StatementDescriptor: '123456789012',
      PaymentOption: ['CREDIT', ' ETRANSFER', ' NONE'],
      LogoBase: null,
      LogoUrl: 'https://www.paymentsource.ca/hs-fs/hubfs/paymentsourcelogo_colour-1.png?width=1153&name=paymentsourcelogo_colour-1.png',
    },
    {
      CreatedBy: '',
      Status: 'T',
      ActivationDate: '',
      SubmittedDate: '2023-01-25',
      StartedReviewDate: '',
      InactiveDate: '2023-01-24',
      Language: 'English',
      Partner_Seq: '5',
      LegalName: 'Jupiter Tuition',
      BinNumber: '987654321',
      GstHstNumber: '123411234',
      DateIncorporated: '2022-08-31',
      LegalStructure: 'Legal Structure',
      BusinessWebsiteUrl: 'PP.com',
      Street: '123 Brew St',
      UnitSuite: '',
      City: 'Port Moody',
      Province: 'BC',
      PostalCode: 'V1V 1V1',
      ServiceCategory: '',
      CountriesOfBusiness: ['Canada', 'USA'],
      AverageTransactionValue: '987321',
      MonthlySalesVolume: '123456789',
      LeadSource: 'Name',
      ExistingPaymentOffered: ['PAD'],
      ReportsEmail: 'kevins+reportsemail@paymentsource.ca',
      PrimaryIndividual: {
        FullName: 'Primary 43598',
        PercentOwnership: '15',
        DateOfBirth: '1990-01-01',
        Address: {
          Unit: '',
          Street: 'Brew St',
          City: 'Pomo',
          Province: 'BC',
          PostalCode: 'V1V 1V1',
        },
        PartnerIndividual_Seq: '677',
        Phone: '777-777-7777',
        Email: 'Prmary+43598@paymentsource.ca',
      },
      AuthorizedIndividual: {
        FullName: '',
        PercentOwnership: '',
        DateOfBirth: '',
        Address: {
          Unit: '',
          Street: '',
          City: '',
          Province: '',
          PostalCode: '',
        },
        PartnerIndividual_Seq: '',
        Phone: '',
        Email: '',
      },
      BusinessOwnershipIndividuals: [
        {
          FullName: 'Authorized 43598',
          PercentOwnership: '16',
          DateOfBirth: '1990-01-01',
          Address: {
            Unit: '',
            Street: 'Brew St',
            City: 'Pomo',
            Province: 'BC',
            PostalCode: 'V1V 1V1',
          },
          PartnerIndividual_Seq: '679',
          Phone: '777-777-7777',
          Email: 'Authorized+43598@paymentsource.ca',
        },
      ],
      DisplayNameEn: 'Jupiter Tuition',
      DisplayNameFr: 'Cours Jupiter',
      PaymentUrlEn: 'https://www.paymentsource.co/paysimply/RC/pay/ppppp',
      PaymentUrlFr: 'https://www.paymentsource.co/paysimply/RC/payer/ppppp',
      VanityUrl: 'ppppp',
      Logo: '',
      LogoAltEn: 'logo alt en',
      LogoAltFr: 'logo alt fr',
      AccountNumberConfig: {
        LabelEn: 'label en',
        HelpTextEn: '',
        PlaceholderEn: 'Placeholder en',
        LabelFr: 'label en',
        HelpTextFr: 'label en',
        PlaceholderFr: 'label en',
        FormatType: '999999999',
        MinLength: '09',
        MaxLength: '',
        Other: 'a',
      },
      OtherEmail: 'otheremail@paymentsource.ca',
      AgreementSignedDate: '2022-08-13',
      BankTransitNumber: '12312',
      BankBranch: '123',
      BankAccountNumber: '*****21',
      StatementDescriptor: '123456789012',
      PaymentOption: ['CREDIT', ' ETRANSFER', ' NONE'],
      LogoBase: null,
      LogoUrl: 'https://www.paymentsource.ca/hs-fs/hubfs/paymentsourcelogo_colour-1.png?width=1153&name=paymentsourcelogo_colour-1.png',
    },
    {
      CreatedBy: '',
      Status: 'I',
      ActivationDate: '',
      SubmittedDate: '2023-01-25',
      StartedReviewDate: '',
      InactiveDate: '2023-01-24',
      Language: 'English',
      Partner_Seq: '6',
      LegalName: 'Jupiter Rent',
      BinNumber: '987654321',
      GstHstNumber: '123411234',
      DateIncorporated: '2022-08-31',
      LegalStructure: 'Legal Structure',
      BusinessWebsiteUrl: 'PP.com',
      Street: '123 Brew St',
      UnitSuite: '',
      City: 'Port Moody',
      Province: 'BC',
      PostalCode: 'V1V 1V1',
      ServiceCategory: '',
      CountriesOfBusiness: ['Canada', 'USA'],
      AverageTransactionValue: '987321',
      MonthlySalesVolume: '123456789',
      LeadSource: 'Name',
      ExistingPaymentOffered: ['PAD'],
      ReportsEmail: 'kevins+reportsemail@paymentsource.ca',
      PrimaryIndividual: {
        FullName: 'Primary 43598',
        PercentOwnership: '15',
        DateOfBirth: '1990-01-01',
        Address: {
          Unit: '',
          Street: 'Brew St',
          City: 'Pomo',
          Province: 'BC',
          PostalCode: 'V1V 1V1',
        },
        PartnerIndividual_Seq: '677',
        Phone: '777-777-7777',
        Email: 'Prmary+43598@paymentsource.ca',
      },
      AuthorizedIndividual: {
        FullName: '',
        PercentOwnership: '',
        DateOfBirth: '',
        Address: {
          Unit: '',
          Street: '',
          City: '',
          Province: '',
          PostalCode: '',
        },
        PartnerIndividual_Seq: '',
        Phone: '',
        Email: '',
      },
      BusinessOwnershipIndividuals: [
        {
          FullName: 'Authorized 43598',
          PercentOwnership: '16',
          DateOfBirth: '1990-01-01',
          Address: {
            Unit: '',
            Street: 'Brew St',
            City: 'Pomo',
            Province: 'BC',
            PostalCode: 'V1V 1V1',
          },
          PartnerIndividual_Seq: '679',
          Phone: '777-777-7777',
          Email: 'Authorized+43598@paymentsource.ca',
        },
      ],
      DisplayNameEn: 'Jupiter Rent',
      DisplayNameFr: 'Jupiter Loyer',
      PaymentUrlEn: 'https://www.paymentsource.co/paysimply/RC/pay/ppppp',
      PaymentUrlFr: 'https://www.paymentsource.co/paysimply/RC/payer/ppppp',
      VanityUrl: 'ppppp',
      Logo: '',
      LogoAltEn: 'logo alt en',
      LogoAltFr: 'logo alt fr',
      AccountNumberConfig: {
        LabelEn: 'label en',
        HelpTextEn: '',
        PlaceholderEn: 'Placeholder en',
        LabelFr: 'label en',
        HelpTextFr: 'label en',
        PlaceholderFr: 'label en',
        FormatType: '999999999',
        MinLength: '09',
        MaxLength: '',
        Other: 'a',
      },
      OtherEmail: 'otheremail@paymentsource.ca',
      AgreementSignedDate: '2022-08-13',
      BankTransitNumber: '12312',
      BankBranch: '123',
      BankAccountNumber: '*****21',
      StatementDescriptor: '123456789012',
      PaymentOption: ['CREDIT', ' ETRANSFER', ' NONE'],
      LogoBase: null,
      LogoUrl: 'https://www.paymentsource.ca/hs-fs/hubfs/paymentsourcelogo_colour-1.png?width=1153&name=paymentsourcelogo_colour-1.png',
    },
  ];
