export const types = {
  CLEAR_ALL_REPORT_DATA: 'CLEAR_ALL_REPORT_DATA',
  SET_SHOW_REPORT_TOOLTIPS: 'SET_SHOW_REPORT_TOOLTIPS',

  // Payments report
  CLEAR_PAYMENTS_REPORT: 'CLEAR_PAYMENTS_REPORT',
  SET_PAYMENTS_REPORT: 'SET_PAYMENTS_REPORT',
  SET_PAYMENTS_REPORT_LOADING: 'SET_PAYMENTS_REPORT_LOADING',

};

export const clearAllReportData = () => ({
  type: types.CLEAR_ALL_REPORT_DATA,
});

export const setShowReportTooltips = (data) => ({
  type: types.SET_SHOW_REPORT_TOOLTIPS,
  payload: data,
});

// Payment reports

export const clearPaymentsReport = (data) => ({
  type: types.CLEAR_PAYMENTS_REPORT,
  payload: data,
});

export const setPaymentsReport = (data) => ({
  type: types.SET_PAYMENTS_REPORT,
  payload: data,
});

export const setPaymentsReportLoading = (data) => ({
  type: types.SET_PAYMENTS_REPORT_LOADING,
  payload: data,
});
