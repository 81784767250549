import { useEffect } from 'react';
import { translate, t } from 'react-switch-lang';

import { moveFocusTo } from '../utils/Accessibility';

function SkipToNav() {
  useEffect(() => {
    const elementExists = document.getElementById('skip-to-nav-button');
    if (elementExists) return;
    const divEl = document.createElement('div');
    const buttonEl = document.createElement('button');
    const buttonTextEl = document.createElement('span');
    divEl.id = 'skip-to-nav-div';
    buttonEl.id = 'skip-to-nav-button';
    buttonTextEl.className = 'skip-to-text';
    buttonTextEl.innerText = t('ButtonDescription_SkipToNavbar');
    divEl.appendChild(buttonEl);
    buttonEl.appendChild(buttonTextEl);
    document.body.appendChild(divEl);
    document.getElementById('skip-to-nav-button').addEventListener('click', () => moveFocusTo('nav-bar', true));
  }, []);

  return null;
}

export default translate(SkipToNav);
