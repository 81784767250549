import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Image from 'next/legacy/image';
import { t, translate } from 'react-switch-lang';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import Link from './Link';
import { useCognito } from '../utils/Cognito';
import { events, logAmpEvent } from '../utils/Amplitude';
import style from '../styles/NavBar.module.scss';
import { setScreenReaderAlert } from '../utils/Accessibility';
import { useRouter } from './Router';

function NavBar({ authPage }) {
  const [showHamburgerDropdown, toggleHamburgerDropdown] = useState(false);
  const [showTextTimeout, setShowTextTimeout] = useState(null);
  const session = useSelector((state) => state.auth.session);
  const cognito = useCognito();
  const router = useRouter();
  const path = router.pathname;

  useEffect(() => {
    if (!showHamburgerDropdown) {
      if (showTextTimeout) return;
      setShowTextTimeout(
        setTimeout(() => {
          setShowTextTimeout(null);
        }, 250)
      );
    } else if (showTextTimeout) {
      clearTimeout(showTextTimeout);
      setShowTextTimeout(null);
    }
  }, [showHamburgerDropdown]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <nav
      id={style.nav}
      className={`${session ? style.session : ''} ${authPage ? style.authPage : ''}`}
    >
      <div
        className={`${style.navContent} ${
          showHamburgerDropdown ? style.expanded : ''
        } maxWidth16`}
        id="nav-bar"
      >
        <div className={style.row}>
          <button
            id={style.Hamburger}
            className={style.corners}
            type="button"
            title={t('ButtonDescription_HamburgerMenu')}
            aria-label={t('ButtonDescription_HamburgerMenu')}
            onClick={() => {
              logAmpEvent(events.USER_CLICKED_NAVBAR_LINK, {
                Link: 'Hamburger Icon',
                Type: showHamburgerDropdown ? 'Hide' : 'Show',
              });
              toggleHamburgerDropdown(!showHamburgerDropdown);
            }}
            data-action="disclosure"
            aria-controls="hamburger-dropdown"
            aria-expanded={showHamburgerDropdown ? 'true' : 'false'}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <div className={style.logoContainer}>
            <Link
              href="/"
              className={style.logo}
              ampEvent={events.USER_CLICKED_NAVBAR_LINK}
              ampEventProps={{ Link: 'Logo' }}
              onClick={() => toggleHamburgerDropdown(false)}
            >
              <Image
                src="/assets/PaySimply-Lg.png"
                alt={t('ImageDescription_PaySimplyLogo')}
                width={100}
                height={30}
                objectFit="contain"
                unoptimized
              />
            </Link>
            <p className={`${style.text} ${style.show}`}>
              {t('NavBar_Partner_Portal')}
            </p>
          </div>
          <div id={style.Links}>
            <div className={style.authLinks}>
              <Link
                href="/reports"
                ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                ampEventProps={{ Link: t('NavBar_Reports', null, 'en') }}
                text={t('NavBar_Reports')}
                aria-label={t('LinkDescription_Reports')}
              />
              {session ? (
                <button
                  id={style.LogoutBtn}
                  type="button"
                  onClick={() => {
                    logAmpEvent(events.USER_CLICKED_NAVBAR_LINK, {
                      Link: t('NavBar_Logout', null, 'en'),
                    });
                    if (path === '/[lang]/services' ||
                    path === '/[lang]/application/before-you-start' ||
                    path === '/[lang]/application/service-information' ||
                    path === '/[lang]/application/contact-information' ||
                    path === '/[lang]/application/agreement' ||
                    path === '/[lang]/application/paysimply-preferences' ||
                    path === '/[lang]/application/payment-options' ||
                    path === '/[lang]/maintenance' ||
                    path === '/[lang]/reports' ||
                    path === '/[lang]/reports/select-partner') {
                      router.push({ pathname: '/logout', query: { to: 'login' } });
                      return;
                    }
                    toggleHamburgerDropdown(false);
                    cognito.signOut().then(() => {
                      logAmpEvent(events.USER_LOGGED_OUT);
                      setScreenReaderAlert(t('ScreenReaderAlert_LogoutSuccess'));
                    });
                  }}
                >
                  {t('NavBar_Logout')}
                </button>
              ) : (
                <Link
                  id={style.SignUpBtn}
                  href="/login"
                  ampEvent={events.USER_CLICKED_NAVBAR_LINK}
                  ampEventProps={{ Link: t('NavBar_SignUp', null, 'en') }}
                  text={t('NavBar_Login')}
                  onClick={() => toggleHamburgerDropdown(false)}
                  aria-label={t('LinkDescription_Login')}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default translate(NavBar);
