import { combineReducers } from 'redux';

import AuthReducer from './AuthReducer';
import PartnerReducer from './PartnerReducer';
import ReportsReducer from './ReportsReducer';

export default combineReducers({
  auth: AuthReducer,
  partner: PartnerReducer,
  reports: ReportsReducer,
});
