export const types = {
  SET_EMAIL: 'SET_EMAIL',
  SET_SESSION: 'SET_SESSION',
  CLEAR_SESSION: 'CLEAR_SESSION',
};

export const setEmail = (email) => ({
  type: types.SET_EMAIL,
  payload: email,
});

export const setSession = (session) => ({
  type: types.SET_SESSION,
  payload: session,
});

export const clearSession = () => ({
  type: types.CLEAR_SESSION,
});
