import { types } from '../actions/PartnerActions';

const initialState = {
  // null partners indicates never been loaded, array indicates a load was made
  partners: null,
  currentPartner: null,
  logoImg: '',
  voidChequeVerified: false,
};

// eslint-disable-next-line default-param-last
function PartnerReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_PARTNERS:
      return { ...state, ...action.payload };
    case types.SET_PARTNERS_ONLY:
      return { ...state, partners: action.payload };
    case types.SET_CURRENT_PARTNERS:
      return { ...state, currentPartner: action.payload };
    case types.SET_LOGO_IMAGE:
      return { ...state, logoImg: action.payload };
    case types.SET_VOID_CHEQUE_VERIFIED:
      return { ...state, voidChequeVerified: action.payload };
    case types.CLEAR_PARTNERS:
    case types.CLEAR_SESSION:
      return initialState;
    default:
      return state;
  }
}

export default PartnerReducer;
