import React, { useCallback, useEffect, useState } from 'react';
import { useRouter as useNextRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { getLanguage, translate } from 'react-switch-lang';
import { setUser as setSentryUser } from '@sentry/nextjs';

import Redirect from './Redirect';
import { getSessionAndAttr } from '../utils/Cognito';
import { setSession, setEmail } from '../redux/actions/AuthActions';
import { setAmpUser } from '../utils/Amplitude';
import { isProduction } from '../utils/HostingEnv';

const unauthUserOnly = ['/login', '/register', '/forgot-password'];
const authUserOnly = ['/services',
  '/application/contact-information',
  '/application/before-you-start',
  '/application/service-information',
  '/application/payment-options',
  '/application/agreement',
  '/application/paysimply-preferences',
  '/maintenance',
  '/reports',
  '/reports/select-partner',
];

export function useRouter() {
  const router = useNextRouter();
  const session = useSelector((state) => state.auth.session);
  const lang = getLanguage();

  const generateHandler = useCallback((routerFunction) => {
    /**
     * @param {string | import('url').UrlObject} url
     * @param {string | import('url').UrlObject} [as] masks `url` for the browser
     * @param {*} [options] object you can define `shallow` and other options
     */
    const handler = (url, as, options) => {
      const isStringUrl = typeof url === 'string';
      let urlString = isStringUrl ? url : url?.pathname;

      if (!urlString) return;

      if (
        // logged in but trying to route to an unauthenticated-users-only path
        (session && unauthUserOnly.find((path) => urlString.endsWith(path))) ||
        // not logged in but trying to route to an authenticated-users-only path
        (!session && authUserOnly.find((path) => urlString.endsWith(path)))
      ) {
        routerFunction(`/${lang}`);
        return;
      }

      urlString = `/${lang}${urlString}`;
      routerFunction(isStringUrl ? urlString : { ...url, pathname: urlString }, as, options);
    };
    return handler;
  }, [lang, session]);

  return {
    ...router,
    push: generateHandler(router.push),
    replace: generateHandler(router.replace),
    // use below functions when required to prevent the automatic handling of
    // session checks or prepending /[lang] to path
    pushStatic: router.push,
    replaceStatic: router.replace,
  };
}

/**
 * Higher-Order Component for handling routing to auth/unauth users only pages.
 * It also wraps the component in the translate HOC.
 * @param {React.Component} Page
 * @param {boolean} [checkAuth] true for auth-only & unauth-only pages
 * @param {boolean} [unauthOnly] if true, redirects to root if signed in
 * @returns {React.Component}
 */
export function checkSession(Page, checkAuth = false, unauthOnly = false) {
  function AuthPage(props) {
    const dispatch = useDispatch();
    const router = useRouter();

    // double exclamation mark to convert truthy/falsy value into true/false boolean value
    const sessionExists = useSelector((state) => !!state.auth.session);

    const [pageState, setPageState] = useState({
      loggedIn: sessionExists,
      loading: checkAuth && !sessionExists,
    });

    // if checking authentication state for redirect purposes,
    // should redirect or not is determined by the result of XNOR on unauthOnly and isLoggedIn
    // i.e. unauthOnly path but logged in / authOnly path but not logged it
    const shouldRedirect = checkAuth && unauthOnly === pageState.loggedIn;

    useEffect(() => {
      if (pageState.loggedIn) return;
      async function loadSession() {
        const newPageState = { ...pageState };
        const [sess, attributes] = await
        getSessionAndAttr(getLanguage()).catch(() => [null, null]);
        if (sess) {
          dispatch(setSession(sess));
          newPageState.loggedIn = true;
        }
        if (attributes) {
          const email = attributes.find((attr) => attr.Name === 'email')?.Value;
          // identify user for analytics
          setAmpUser(email);
          if (isProduction) setSentryUser({ email });
          dispatch(setEmail(email));
          const path = router.pathname;
          if (path === '/[lang]/application/before-you-start' ||
          path === '/[lang]/application/service-information' ||
          path === '/[lang]/application/contact-information' ||
          path === '/[lang]/application/agreement' ||
          path === '/[lang]/application/paysimply-preferences' ||
          path === '/[lang]/application/payment-options') {
            router.push('/services');
            return;
          }
        }
        newPageState.loading = false;
        setPageState(newPageState);
      }
      loadSession();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      // if session state changes after the page is already loaded
      if (pageState.loading || sessionExists) return;
      // the user is either logging in or logging out
      // for logging in, redirect should be handled on the form onSubmit
      // hence this useEffect will only deal with logging out while on a auth-user-only page
      if (checkAuth && !unauthOnly) router.replace('/');
    }, [sessionExists]); // eslint-disable-line react-hooks/exhaustive-deps

    if (pageState.loading) return null;
    return shouldRedirect ? <Redirect path={unauthOnly ? '/services' : '/'} /> : <Page {...props} />;
  }

  const layoutComponentName = Page.displayName || Page.name || 'Component';
  AuthPage.displayName = `checkAuth(${layoutComponentName})`;

  return translate(AuthPage);
}
